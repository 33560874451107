import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
// Utilities
import kebabCase from 'lodash/kebabCase';
import { rhythm } from '../utils/typography';
import { Link, graphql } from 'gatsby';

const TagsPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const group = data.allMarkdownRemark.group;

    const { social, author } = data.site.siteMetadata;
    const ogImage = data.imageSharp.fluid.src;

    return (
        <Layout location={location} title={siteTitle} social={social} author={author}>
            <SEO title={siteTitle} description="linda 創作空間" image={ogImage} />
            <h1>All Tags:</h1>
            <div css={css(containerStyle)}>
                {group.map((tag) => (
                    <div css={css(itemContainerStyle)} key={tag.fieldValue}>
                        <Link
                            css={css({ boxShadow: 'none' })}
                            to={`/tags/${kebabCase(tag.fieldValue)}/`}
                        >
                            <div css={css(itemStyle)}>
                                {tag.fieldValue} ({tag.totalCount})
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div css={css(authorStyle)}>
                <Bio />
            </div>
        </Layout>
    );
};

TagsPage.propTypes = {
    location: PropTypes.object,
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
};

export default TagsPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                author {
                    name
                    summary
                }
                social {
                    facebook
                    twitter
                    instagram
                }
            }
        }
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        imageSharp(fluid: { src: { regex: "/ogImage.jpg/" } }) {
            fluid {
                src
            }
        }
    }
`;

const authorStyle = {
    marginTop: rhythm(2),
};

const itemStyle = {
    boxShadow: '0 20px 20px -20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(0, 0, 0, 0.06)',
    maxWidth: '100%',
    overflow: 'hidden',
    padding: `${rhythm(1 / 2)} ${rhythm(1 / 2)}`,
};

const itemContainerStyle = {
    width: '33%',
    padding: '16px 8px',
    '@media only screen and (max-width: 768px)': {
        width: '50%',
    },
    '@media only screen and (max-width: 489px)': {
        width: '100%',
    },
};

const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
};
